import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { useMonthPicker } from '../../components/month-picker/useMonthPicker';

import { TransportOrdersFilters as TransportOrdersFiltersType } from '../../domain/transportOrdersFilters';
import { useContractorsRepository } from '../../repository/contractors/useContractorsRepository';
import { useFindAllUsersRepository } from '../../repository/users/useFindAllUsersRepository';
import { TransportOrderSelectByOrderId } from './TransportOrderSelectByOrderId';

type TransportOrdersFiltersProps = {
    filters: TransportOrdersFiltersType;
    onFiltersChange: (filters: TransportOrdersFiltersType | undefined) => void;
    className?: string;
}

export const TransportOrdersFilters = ({ filters, onFiltersChange, className }: TransportOrdersFiltersProps) => {
    const [filtersState, setFiltersState] = useState<TransportOrdersFiltersType>({});

    const { data: usersAccounts = [] } = useFindAllUsersRepository();
    const { data: contractors = [] } = useContractorsRepository();

    const selectedUserId = filtersState.creator?.id || '';
    const selectedContractorId = filtersState.contractor?.id || '';
    const searchQuery = filtersState.query?.value || '';

    const { MonthPicker, reset: resetMonthPicker } = useMonthPicker({
        month: Number(filters.orderMonthYear?.month),
        year: Number(filters.orderMonthYear?.year),
        onMonthYearChange: (newMonth, newYear) => setFiltersState(prevState => ({
            ...prevState,
            orderMonthYear: newMonth !== 0 && newYear !== 0 ? { month: newMonth, year: newYear } : undefined,
        })),
    });


    useEffect(() => {
        setFiltersState(filters);
    }, [filters]);

    const handleQueryChange = (query: string) => {
        setFiltersState((prevState) => ({
            ...prevState,
            query: { value: query },
        }));
    };

    const handleCreatorChange = (selectedCreatorId?: string) => {
        setFiltersState((prevState) => ({
            ...prevState,
            creator: selectedCreatorId ? { id: selectedCreatorId } : undefined,
        }));
    };

    const handleContractorChange = (selectedContractorId?: string) => {
        setFiltersState((prevState) => ({
            ...prevState,
            contractor: selectedContractorId ? { id: selectedContractorId } : undefined,
        }));
    };

    const handleClear = () => {
        onFiltersChange(undefined);
        resetMonthPicker()
    };

    const handleSearchSubmit = () => {
        onFiltersChange(filtersState);
    };

    const usersAsOptions = usersAccounts.map((user) => ({
        value: user.sub,
        label: `${user.firstName} ${user.lastname}`,
    }));

    const selectedUser = usersAsOptions.find((user) => user.value === selectedUserId);

    const contractorsAsOptions = contractors.map((contractor) => ({
        value: contractor.id,
        label: contractor.name,
    }));

    const selectedContractor = contractorsAsOptions.find((contractor) => contractor.value === selectedContractorId);

    return <div>
        <TransportOrderSelectByOrderId className="width-200 mb-2" />
        <div className={`d-flex flex-row align-items-end ${className}`}>
            <Form className="d-flex flex-row align-items-end" onSubmit={(e) => {
                e.preventDefault();
                handleSearchSubmit();
            }}>
                <div className="me-3">
                    <h6>Wyszukaj po tekście</h6>
                    <FormControl
                        name="query"
                        className="search-input"
                        type="text"
                        placeholder="Wyszukaj po tekście"
                        onChange={(e) => handleQueryChange(e.target.value)}
                        autoComplete="on"
                        value={searchQuery} />
                </div>
                <div className="me-3">
                    <h6>Wyszukaj po miesiacu i roku</h6>
                    <MonthPicker />
                </div>
                <div className="me-3">
                    <h6>Wyszukaj po spedytorze</h6>
                    <Select
                        key={`creators-select-${selectedUserId}`}
                        isClearable
                        placeholder="Wybierz"
                        options={usersAsOptions}
                        value={selectedUser}
                        onChange={(user) => handleCreatorChange(user?.value)}
                    />
                </div>
                <div className="me-3">
                    <h6>Wyszukaj po podwykonawcy</h6>
                    <Select
                        key={`contractors-select-${selectedContractorId}`}
                        isClearable
                        placeholder="Wybierz"
                        options={contractorsAsOptions}
                        value={selectedContractor}
                        onChange={(contractor) => handleContractorChange(contractor?.value)}
                    />
                </div>
                <Button className="me-2" variant="primary" onClick={handleSearchSubmit} type="submit">Szukaj</Button>
                <Button variant="outline-primary" onClick={handleClear}>Wyczyść</Button>
            </Form>
        </div>
    </div>;
};