import moment from 'moment';
import { useCurrencyRepository } from '../../repository/currency/useCurrencyRepository';
import {
    useTransportOrdersByCreatorAndMonthRepository,
} from '../../repository/transport-orders/useTransportOrdersByCreatorAndMonthRepository';
import { useLoggedInUser } from '../users/LoggedInUserProvider';
import { SpeditorProfitForMonth } from './SpeditorProfitForMonth';

const now = moment();
const currentMonthNumber = now.month() + 1;
const currentYearNumber = now.year();

const previousMonth = moment().subtract(1, 'months');
const previousMonthNumber = previousMonth.month() + 1;
const previousMonthYear = previousMonth.year();

export const SpeditorProfit = () => {
    const { loggedInUser } = useLoggedInUser();
    const { data: currentMonthTransportOrders } = useTransportOrdersByCreatorAndMonthRepository(
        loggedInUser.sub,
        currentMonthNumber,
        currentYearNumber,
    );

    const { data: previousMonthTransportOrders } = useTransportOrdersByCreatorAndMonthRepository(
        loggedInUser.sub,
        previousMonthNumber,
        previousMonthYear,
    );

    const { data: currencyRecord } = useCurrencyRepository();

    if (!currentMonthTransportOrders || !previousMonthTransportOrders || !currencyRecord) {
        return null;
    }

    return <>
        <SpeditorProfitForMonth
            transportOrders={currentMonthTransportOrders}
            currencyRecord={currencyRecord}
            month={currentMonthNumber}
            year={currentYearNumber}
        />
        <SpeditorProfitForMonth
            transportOrders={previousMonthTransportOrders}
            currencyRecord={currencyRecord}
            month={previousMonthNumber}
            year={previousMonthYear}
        />
    </>;
};