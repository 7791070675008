import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

import { TransportOrder } from '../../../domain/transportOrder';
import { useCurrencyRepository } from '../../../repository/currency/useCurrencyRepository';
import { formattedEurToPln, prepareFreight } from '../../../utils/currency';

export const TransportOrderProfit = ({ transportOrder }: { transportOrder: TransportOrder }) => {
    const { data: currencyRecord } = useCurrencyRepository();

    if (!currencyRecord) {
        return null;
    }

    const { clientValue, contractorValue, currency, computed } = prepareFreight(transportOrder.freight, currencyRecord);

    const profit = clientValue - contractorValue;

    if (!computed) {
        return <div
            className={`${(!isNaN(profit) && profit > 0) ? 'text-success' : 'text-danger'}`}>
            <span>Marża: {isNaN(profit) ? '???' : profit.toLocaleString('pl-PL', {
                style: 'currency',
                currency,
            })}</span>
        </div>;
    }

    return <OverlayTrigger
        placement="top"
        overlay={
            <Popover id="transport-order-popover">
                <Popover.Header>Różne waluty</Popover.Header>
                <Popover.Body>
                    Różne waluty klienta({transportOrder.freight.clientCurrency}) i
                    podwykonawcy({transportOrder.freight.currency}).
                    Obliczone po kursie {formattedEurToPln(currencyRecord)}
                </Popover.Body>
            </Popover>
        }>
        <div>
            <span
                className={`${(!isNaN(profit) && profit > 0) ? 'text-success' : 'text-danger'}`}>Marża: {isNaN(profit) ? '???' : profit.toLocaleString('pl-PL', {
                style: 'currency',
                currency,
            })}</span>
            <InfoCircle className="ms-1" />
        </div>
    </OverlayTrigger>;
};