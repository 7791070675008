import { CurrencyRecord, SupportedCurrency } from '../domain/currency';
import { TransportOrder } from '../domain/transportOrder';

export const getAmountInPLN = (currencyRecord: CurrencyRecord, value: number, currency: SupportedCurrency) => {
    if (currency === 'PLN') {
        return value;
    }

    const currencyRate = currencyRecord[currency];

    return value * currencyRate.exchangeRate;
};

export const prepareFreight = (freight: TransportOrder['freight'], currencyRecord: CurrencyRecord): {
    clientValue: number,
    contractorValue: number,
    currency: TransportOrder['freight']['currency'],
    computed: boolean,
} => {
    const { clientValue, clientCurrency, value, currency } = freight;

    if (currency === clientCurrency) {
        return {
            clientValue: Number(clientValue),
            contractorValue: Number(value),
            currency,
            computed: false,
        };
    }

    const clientValueInPln = getAmountInPLN(currencyRecord, Number(clientValue || 0), clientCurrency);
    const contractorValueInPln = getAmountInPLN(currencyRecord, Number(value), currency);

    return {
        clientValue: clientValueInPln,
        contractorValue: contractorValueInPln,
        currency: 'PLN',
        computed: true,
    };
};

export const formattedEurToPln = (currencyRecord: CurrencyRecord) => `${(1).toLocaleString('pl-PL', {
        style: 'currency',
        currency: 'EUR',
    })} = ${currencyRecord.EUR.exchangeRate.toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
        })}`