import moment from 'moment';
import { Button } from 'react-bootstrap';
import { TransportOrdersFilters as TransportOrdersFiltersType } from '../../domain/transportOrdersFilters';
import { useLoggedInUser } from '../users/LoggedInUserProvider';

type TransportOrdersQuickFiltersProps = {
    filters: TransportOrdersFiltersType;
    onFiltersChange: (filters: TransportOrdersFiltersType | undefined) => void;
}

const now = moment();
const currentMonth = now.month() + 1;
const currentYear = now.year();

const pastMonthDate = now.clone().subtract(1, 'months');
const pastMonth = pastMonthDate.month() + 1;
const pastMonthYear = pastMonthDate.year();


const nextMonthDate = now.clone().add(1, 'months');
const nextMonth = nextMonthDate.month() + 1;
const nextMonthYear = nextMonthDate.year();

export const TransportOrdersQuickFilters = ({ filters, onFiltersChange }: TransportOrdersQuickFiltersProps) => {
    const { loggedInUser } = useLoggedInUser();

    const isCurrentSpeditor = filters.creator?.id === loggedInUser.sub;

    const isCurrentMonth = filters.orderMonthYear?.month === currentMonth && filters.orderMonthYear?.year === currentYear;
    const isPastMonth = filters.orderMonthYear?.month === pastMonth && filters.orderMonthYear?.year === pastMonthYear;
    const isNextMonth = filters.orderMonthYear?.month === nextMonth && filters.orderMonthYear?.year === nextMonthYear;

    const handleYourOrdersClick = () => {
        if (isCurrentSpeditor) {
            onFiltersChange({
                ...filters,
                creator: undefined,
            });
        } else {
            onFiltersChange({
                ...filters,
                creator: {
                    id: loggedInUser.sub,
                },
            });
        }
    };

    const handleCurrentMonthClick = () => {
        if (isCurrentMonth) {
            onFiltersChange({
                ...filters,
                orderMonthYear: undefined,
            })
        } else {
            onFiltersChange({
                ...filters,
                orderMonthYear: {
                    month: currentMonth,
                    year: currentYear,
                },
            });
        }
    };

    const handlePastMonthClick = () => {
        if (isPastMonth) {
            onFiltersChange({
                ...filters,
                orderMonthYear: undefined,
            })
        } else {
            onFiltersChange({
                ...filters,
                orderMonthYear: {
                    month: pastMonth,
                    year: pastMonthYear,
                },
            });
        }
    };

    const handleNextMonthClick = () => {
        if (isNextMonth) {
            onFiltersChange({
                ...filters,
                orderMonthYear: undefined,
            })
        } else {
            onFiltersChange({
                ...filters,
                orderMonthYear: {
                    month: nextMonth,
                    year: nextMonthYear,
                },
        });
        }
    };

    return (
        <ul className="d-flex flex-row list-unstyled">
            <li className="me-2">
                <Button onClick={handleYourOrdersClick} variant={isCurrentSpeditor ? 'primary' : 'outline-primary'}>
                    Twoje zlecenia
                </Button>
            </li>
            <li className="me-2">
                <Button onClick={handleCurrentMonthClick} variant={isCurrentMonth ? 'primary' : 'outline-primary'}>
                    Ten miesiąc
                </Button>
            </li>
            <li className="me-2">
                <Button onClick={handlePastMonthClick} variant={isPastMonth ? 'primary' : 'outline-primary'}>
                    Poprzedni miesiąc
                </Button>
            </li>
            <li>
                <Button onClick={handleNextMonthClick} variant={isNextMonth ? 'primary' : 'outline-primary'}>
                    Kolejny miesiąc
                </Button>
            </li>
        </ul>
    );
};