import { useEffect, useState } from 'react';

import { MonthPicker } from './MonthPicker';

type UseMonthPickerProps = {
    month?: number;
    year?: number;
    onMonthYearChange: (month: number, year: number) => void;
}

export const useMonthPicker = ({ month = 0, year = 0, onMonthYearChange }: UseMonthPickerProps) => {
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [selectedYear, setSelectedYear] = useState(year);

    useEffect(() => {
        setSelectedMonth(month || 0);
        setSelectedYear(year || 0);
    }, [month, year]);

    const handleMonthYearChange = (newMonth: number, newYear: number) => {
        setSelectedMonth(newMonth);
        setSelectedYear(newYear);

        onMonthYearChange(newMonth, newYear);
    };

    const handleReset = () => {
        setSelectedMonth(0);
        setSelectedYear(0);
    }

    return {
        selectedMonth,
        selectedYear,
        reset: handleReset,
        MonthPicker: () => <MonthPicker
            month={selectedMonth}
            year={selectedYear}
            onChange={handleMonthYearChange}
        />,
    };
};
