import React from 'react';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

import { FormElement } from '../../../components/FormElement';
import { SupportedCurrency } from '../../../domain/currency';
import { TransportOrder } from '../../../domain/transportOrder';
import { useCurrencyRepository } from '../../../repository/currency/useCurrencyRepository';
import { formattedEurToPln, prepareFreight } from '../../../utils/currency';

type FreightType = TransportOrder['freight'];

type FreightProps = {
    freight: FreightType;
    setFreight: (newValue: FreightType) => void;
}

export const Freight = ({ freight, setFreight }: FreightProps) => {
    const handleFreightChange = (newFreight: FreightType) => {
        setFreight(newFreight);
    };

    const handleValueChange = (newValue: FreightType['value']) => handleFreightChange({
        ...freight,
        value: newValue,
    });
    const handleCurrencyChange = (newCurrency: FreightType['currency']) => handleFreightChange({
        ...freight,
        currency: newCurrency,
    });
    const handleVatRateChange = (newVatRate: number) => handleFreightChange({
        ...freight,
        vatRate: newVatRate,
    });

    const handlePaymentDaysRateChange = (newPaymentDays: number) => handleFreightChange({
        ...freight,
        paymentDays: newPaymentDays,
    });

    return (
        <FormElement className="d-inline-block" headingText="Fracht">
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bolder">Kwota</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        value={freight.value}
                        onChange={(e) => handleValueChange(e.target.value)}
                    />
                    <Form.Select
                        value={freight.currency}
                        onChange={(e) => handleCurrencyChange(e.target.value as FreightType['currency'])}
                    >
                        <option value="EUR">EUR</option>
                        <option value="PLN">PLN</option>
                    </Form.Select>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bolder">VAT</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="number"
                        value={freight.vatRate}
                        onChange={(e) => handleVatRateChange(Number(e.target.value))} />
                    <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label className="m-0 fw-bolder">Termin płatności</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="number"
                        value={freight.paymentDays}
                        onChange={(e) => handlePaymentDaysRateChange(Number(e.target.value))} />
                    <InputGroup.Text>dni</InputGroup.Text>
                </InputGroup>
            </Form.Group>
        </FormElement>
    );
};

type FreightProfitProps = {
    freight: FreightType;
    setFreight: (newValue: FreightType) => void;
}

export const FreightProfit = ({ freight, setFreight }: FreightProfitProps) => {
    const { data: currencyRecord } = useCurrencyRepository();

    if (!currencyRecord) {
        return null;
    }

    const handleClientValueChange = (newValue: FreightType['clientValue']) => setFreight({
        ...freight,
        clientValue: newValue === '' ? undefined : newValue,
    });
    const handleClientCurrencyChange = (newCurrency: FreightType['clientCurrency']) => setFreight({
        ...freight,
        clientCurrency: newCurrency,
    });

    const { clientValue, contractorValue, currency, computed } = prepareFreight(freight, currencyRecord);

    const profit = clientValue - contractorValue;

    return (
        <FormElement className="d-inline-block" headingText="Oferta klienta*">
            <Form.Label className="fst-italic text-secondary">(*) Niewidoczny na wygenerowanym pdf</Form.Label>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bolder">Kwota</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        value={freight.clientValue}
                        onChange={(e) => handleClientValueChange(e.target.value)}
                    />
                    <Form.Select
                        value={freight.clientCurrency}
                        onChange={(e) => handleClientCurrencyChange(e.target.value as FreightType['clientCurrency'])}
                    >
                        <option value="EUR">EUR</option>
                        <option value="PLN">PLN</option>
                    </Form.Select>
                </InputGroup>
            </Form.Group>
            {computed ? (<OverlayTrigger
                placement="top"
                overlay={<Tooltip>Po kursie {formattedEurToPln(currencyRecord)}</Tooltip>}>
                <div>
                    <FormattedProfit profit={profit} currency={currency} />
                    <InfoCircle className="ms-1" />
                </div>
            </OverlayTrigger>) : <FormattedProfit profit={profit} currency={currency} />
            }
        </FormElement>
    );
};

const FormattedProfit = ({ profit, currency }: { profit: number, currency: SupportedCurrency }) => (
    <>
        Marża: {isNaN(profit) ? '?' :
        <span
            className={profit > 0 ? 'text-success' : profit === 0 ? 'text-warning' : 'text-danger'}>{profit.toLocaleString('pl-PL', {
            style: 'currency',
            currency,
        })}</span>
    }
    </>
);
