import { Link } from 'react-router-dom';
import { CurrencyRecord, SupportedCurrency } from '../../domain/currency';
import { TransportOrder } from '../../domain/transportOrder';

import { formattedEurToPln, getAmountInPLN, prepareFreight } from '../../utils/currency';
import { useLoggedInUser } from '../users/LoggedInUserProvider';

type SpeditorProfitForMonthProps = {
    transportOrders: Array<TransportOrder>;
    currencyRecord: CurrencyRecord;
    month: number;
    year: number;
    className?: string;
}

export const SpeditorProfitForMonth = ({ transportOrders, currencyRecord, month, year, className = '' }: SpeditorProfitForMonthProps) => {
    const { loggedInUser } = useLoggedInUser();

    if (transportOrders.length === 0) {
        return null;
    }

    const displayedMonthNumber = month < 10 ? `0${month}` : month;

    const groupedTotalProfits = Object.entries(transportOrders
        .filter(({ status }) => status !== 'DRAFT' && status !== 'STORNO')
        .reduce((acc, curr) => {
            const { clientValue, contractorValue, currency } = prepareFreight(curr.freight, currencyRecord);

            const profit = clientValue - contractorValue;
            if (isNaN(profit)) {
                return acc;
            }

            acc[currency] = acc[currency] || 0;
            acc[currency] += clientValue - contractorValue;
            return acc;
        }, {} as Record<SupportedCurrency, number>));

    if (groupedTotalProfits.length === 0) {
        return null;
    }

    const totalInPln = groupedTotalProfits.map(([currency, profit]) => getAmountInPLN(currencyRecord, profit, currency as SupportedCurrency))
        .reduce((acc, curr) => acc + curr, 0);

    return <h6 className={`text-black-50 fst-italic ${className}`}>
        Marża <Link
        to={`/transport-orders?orderYear=${year}&orderMonth=${month}&creatorId=${loggedInUser.sub}&showProfit=on`}>
        {displayedMonthNumber}-{year}
    </Link> to {groupedTotalProfits.map(([currency, profit], index) => <>
        <span
            key={currency}
            className={profit > 0 ? 'text-success' : 'text-danger'}>{profit.toLocaleString('pl-PL', {
            style: 'currency',
            currency,
        })}</span>{index < groupedTotalProfits.length - 1 ? ', ' : ''}
    </>)} <span>({totalInPln.toLocaleString('pl-PL', {
        style: 'currency',
        currency: 'PLN',
    })} po kursie {formattedEurToPln(currencyRecord)})</span>
    </h6>;
};