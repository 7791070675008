import React from 'react';
import { FileEarmarkPlus } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ActionItem } from '../../../components/ActionItem';
import {
    useChangeTransportOrderStatusRepository,
} from '../../../repository/transport-orders/useChangeTransportOrderStatusRepository';

type ActivateDraftTransportOrderActionItemProps = {
    transportOrderId: string;
}

export const ActivateDraftTransportOrderActionItem = ({ transportOrderId }: ActivateDraftTransportOrderActionItemProps) => {
    const navigate = useNavigate();
    const { changeTransportOrderStatus } = useChangeTransportOrderStatusRepository();

    const handleDraftTransportOrderActivation = async () => {
        const activatedTransportOrder = await changeTransportOrderStatus({ transportOrderId, newStatus: 'CREATED' });
        toast.success('Pomyślnie zmieniono status zlecenia');

        navigate({
            pathname: `/transport-orders/${activatedTransportOrder.id}`,
        });
    };

    return <ActionItem onClick={handleDraftTransportOrderActivation}>
        <FileEarmarkPlus className="me-1 width-20" />Nadaj numer
    </ActionItem>;
};
