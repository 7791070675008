import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { CurrencyRecord } from '../../domain/currency';

export const useCurrencyRepository = () => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['currency'],
        staleTime: 60 * 60 * 1000, // 60 minutes
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/currency`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            return await response.json() as CurrencyRecord;
        },
    });

    return {
        ...query,
    };
};