import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ChatLeftText } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EditableTransportOrderBadge } from '../../components/EditableTransportOrderBadge';
import {
    TransportOrderContractorLinkWithContactDetails,
} from '../../components/TransportOrderContractorLinkWithContactDetails';

import { TransportOrder, TransportOrderStatus } from '../../domain/transportOrder';
import {
    useChangeTransportOrderStatusRepository,
} from '../../repository/transport-orders/useChangeTransportOrderStatusRepository';
import { TransportOrderProfit } from './components/TansportOrderProfit';

type TransportOrderAddressCellProps = {
    address: string;
    isShortened: boolean
}

const TransportOrderAddressCell = ({ address, isShortened }: TransportOrderAddressCellProps) => (
    <td className={`${isShortened ? `text-truncate` : ''}`} style={{ minWidth: 250, maxWidth: 250 }}>
        {address}
    </td>
);

const dateToString = (date: Date) =>
    date.toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

const buildAddress = (items: TransportOrder['loading'] | TransportOrder['unloading']) => {
    if (items.length === 1) {
        return items[0].address;
    }

    return items.map(item => item.address).join(', ');
};

const buildDate = (items: TransportOrder['loading'] | TransportOrder['unloading']) => {
    const buildText = (from: Date, to: Date) => {
        const fromText = dateToString(from);
        const toText = dateToString(to);

        return `${fromText}${fromText === toText ? '' : `-${toText}`}`;
    };

    if (items.length === 1) {
        return buildText(items[0].date, items[0].endDate);
    }

    const firstDateFrom = [...items].sort((a, b) => a.date.getTime() - b.date.getTime())[0];
    const lastDateTo = [...items].sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0];

    return buildText(firstDateFrom.date, lastDateTo.endDate);
};

type TransportOrdersTableRowProps = {
    transportOrdersItem: TransportOrder;
    onItemChange: () => Promise<void>;
    showProfit: boolean;
}

export const TransportOrdersTableRow = ({ transportOrdersItem, onItemChange, showProfit }: TransportOrdersTableRowProps) => {
    const { changeTransportOrderStatus } = useChangeTransportOrderStatusRepository();

    const [rowHovered, setRowHovered] = useState(false);
    const navigate = useNavigate();

    const { id, orderId, status, loading, unloading, contractor, creator, comment } = transportOrdersItem;
    const displayProfit = showProfit && transportOrdersItem.status !== 'DRAFT' && transportOrdersItem.status !== 'STORNO';

    const handleMouseEnterRow = () => setRowHovered(true);
    const handleMouseLeavesRow = () => setRowHovered(false);
    const handleRowClick = (e: { ctrlKey: boolean, metaKey: boolean }) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(`/transport-orders/${id}`, '_blank');
        } else {
            navigate(`/transport-orders/${id}`);
        }
    };

    const onStatusChange = async (newStatus: TransportOrderStatus) => {
        await changeTransportOrderStatus({ transportOrderId: id, newStatus });
        await onItemChange();

        toast.success('Pomyślnie zmieniono status');
    };

    const loadingAddress = buildAddress(loading);
    const unloadingAddress = buildAddress(unloading);

    const loadingDateText = buildDate(loading);
    const unloadingDateText = buildDate(unloading);

    const renderCommentPopover = (
        <Popover id="transport-order-popover">
            <Popover.Header>Komentarz</Popover.Header>
            <Popover.Body>{comment}</Popover.Body>
        </Popover>
    );

    return (
        <tr
            className={`transition ${rowHovered ? 'cursor-pointer' : ''}`}
            onClick={handleRowClick}
            onMouseEnter={handleMouseEnterRow}
            onMouseLeave={handleMouseLeavesRow}
        >
            <td className="width-0">
                <div className="d-flex align-items-center justify-content-between">
                    <Link
                        className="p-0 text-decoration-none text-nowrap"
                        to={`/transport-orders/${id}`}
                    >
                        {orderId}
                    </Link>
                    {comment && <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom-start"
                        overlay={renderCommentPopover}>
                        <ChatLeftText className="ms-1 cursor-pointer" />
                    </OverlayTrigger>}
                </div>
            </td>
            <td className="width-0 text-center">
                <EditableTransportOrderBadge status={status} onStatusChange={onStatusChange} />
            </td>
            <TransportOrderAddressCell isShortened={!rowHovered} address={loadingAddress} />
            <TransportOrderAddressCell isShortened={!rowHovered} address={unloadingAddress} />
            <td className="width-0">{loadingDateText}</td>
            <td className="width-0">{unloadingDateText}</td>
            <td className={`${!rowHovered ? `text-truncate` : ''}`} style={{ minWidth: 250, maxWidth: 250 }}>
                {contractor && <TransportOrderContractorLinkWithContactDetails contractor={contractor} />}
            </td>
            <td className="width-0 text-nowrap">
                {creator.name}
                {displayProfit && <TransportOrderProfit transportOrder={transportOrdersItem} />}
            </td>
        </tr>
    );
};
